import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"

import './modeling.component.less';

import MapModel from "../../assets/img/modeling/img/map-model.png"
import Forecast from "../../assets/img/modeling/img/forecast.jpg"
import PlumePoster from "../../assets/img/modeling/img/plume-poster.jpg"
import ForecastPoster from "../../assets/img/modeling/img/forecast-poster.jpg"
import Plume from "../../assets/video/modeling/plume.mp4"
import ForecastVideo from "../../assets/video/modeling/forecast.mp4"
import {useI18next} from "gatsby-plugin-react-i18next";
import SEO from "../seo";

const ModelingComponent = () => {
    const { t, i18n } = useTranslation()
    const { originalPath } = useI18next()

    return (
        <div>
            <SEO description={t("metaDescriptionSimulation")} lang={i18n.language} title={t("titleSimulation")} link={"https://cityair.ru/"+ i18n.language + originalPath} />
            <div className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 flex_box_col flex_box_align_ipad">
                            <div className="color_purple modeling_main_block__title">{t("simulation")}</div>
                            <div className="color_black modeling_main_block__sub_title modeling_main_block__sub_title-en">
                                {t("toolsForSimulation")}
                            </div>
                            <div className="color_black_light modeling_main_block__description">
                                {t("cityAirNumericalCalculations")}
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6" />
                    </div>
                </div>
                <div className="wrapper_img_modeling">
                    <img alt="" src={MapModel} className="img_modeling_map_model" />
                </div>
                
            </div>

            <div className="wrapper_container modeling__plume">
                <div className="modeling-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                <div className="modeling-block__title">
                                    {t("cityAirPlume")}
                                </div>
                                <div className="modeling-block__subtitle">
                                    {t("systemModelingDispersion")}
                                </div>
                                <div className="video-block video-block_position-relative video-block_display-mobile">
                                    <div className="video-block__mac-wrapper">
                                        <div className="video-block__mac-elements">
                                            <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                            <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                            <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                                        </div>
                                        <div className="video-block__video-wrapper">
                                            <video loop autoPlay muted playsInline poster={PlumePoster} className="video-block__video" >
                                                <source src={Plume} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <div className="platform_block__info-important">
                                    Software included in <a target="_blank" href="https://reestr.digital.gov.ru/reestr/309010/" className="platform_block__info-important__link">Unified register of Russian programs</a> for computers and databases​
                                </div> 
                                <div className="modeling-block__list color_black_light">
                                    <ul>
                                        <li className="content_container__list-li ">
                                            {t("settingSourceCharacteristics")}
                                        </li>
                                        <li className="content_container__list-li ">
                                            {t("plumePropagationForecast")}
                                        </li>
                                        <li className="content_container__list-li">
                                            {t("settingBreakpoints")}
                                        </li>
                                        <li className="content_container__list-li">
                                            {t("creationOfSources")}
                                        </li>
                                        <li className="content_container__list-li">
                                            {t("archiveOfCalculations")}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="video-block video-block_position-absolute video-block_position-right video-block_display-desktop">
                        <div className="video-block__mac-wrapper">
                            <div className="video-block__mac-elements">
                                <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                            </div>
                            <div className="video-block__video-wrapper">
                                <video loop autoPlay muted playsInline poster={PlumePoster} className="video-block__video" >
                                    <source src={Plume} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container modeling__forecast">
                <div className="modeling-block">
                    <div className="container">
                        <div className="row">
                        <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7" />
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                <div className="modeling-block__title">
                                    {t("cityAirForecast")}
                                </div>
                                <div className="modeling-block__subtitle">
                                    {t("systemCalculatingTransferChemically")}
                                </div>
                                <div className="video-block video-block_position-relative video-block_display-mobile">
                                    <div className="video-block__mac-wrapper">
                                        <div className="video-block__mac-elements">
                                            <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                            <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                            <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                                        </div>
                                        <div className="video-block__video-wrapper">
                                            <video loop autoPlay muted playsInline poster={ForecastPoster} className="video-block__video" >
                                                <source src={ForecastVideo} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <div className="platform_block__info-important">
                                    Software included in <a target="_blank" href="https://reestr.digital.gov.ru/reestr/309010/" className="platform_block__info-important__link">Unified register of Russian programs</a> for computers and databases​
                                </div> 
                                <div className="modeling-block__list color_black_light">
                                    <ul>
                                        <li className="content_container__list-li ">
                                            {t("buildingForecastOfConcentration")}
                                        </li>
                                        <li className="content_container__list-li ">
                                            {t("settingBreakpoints")}
                                        </li>
                                        <li className="content_container__list-li">
                                            {t("archiveOfCalculations")}
                                        </li>
                                    </ul>
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div className="video-block video-block_position-absolute video-block_position-left video-block_display-desktop">
                        <div className="video-block__mac-wrapper">
                            <div className="video-block__mac-elements">
                                <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                            </div>
                            <div className="video-block__video-wrapper">
                                <video loop autoPlay muted playsInline poster={ForecastPoster} className="video-block__video" >
                                    <source src={ForecastVideo} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container">
                <div className="container modeling_block_2__container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 relative modeling_block_2__string">
                            <div className="modeling_block_3__title">{t("ourSimulation")}</div>
                            <img alt="" src={Forecast} className="img_modeling_forecast" />
                        </div>
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1" />
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 color_black_light">
                            <div className="modeling_block_3__string">
                                <div className="block__number">1.</div>
                                <div>{t("theModelsWeUseTake")}</div>
                            </div>
                            <div className="modeling_block_3__string">
                                <div className="block__number">2.</div>
                                <div>{t("theSimulationIs")}</div>
                            </div>
                            <div className="modeling_block_3__string modeling_block__string-last">
                                <div className="block__number">3.</div>
                                <div>{t("dataFromCityAir")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ModelingComponent.propTypes = {}

ModelingComponent.defaultProps = {}

export default ModelingComponent
